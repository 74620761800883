@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
/* topics style */
.topics .toggleButton {
  margin: 0 0 20px;
}

.topics .toggleButton svg {
  margin: 0 10px 0 0;
}

.topics .list.archive {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.topics .list.archive li {
  width: 48%;
  margin: 0 4% 20px 0;
  display: inline-block;
}

@media only screen and (min-width: 961px) {
  .topics .list.archive li {
    width: 24%;
    margin: 0 1.3% 20px 0;
  }
}

.topics .list.archive li .date .tag {
  width: 60px;
  margin: 0 0 0 10px;
}

.topics .list.archive li:nth-child(2n) {
  margin: 0 0 20px 0;
}

@media only screen and (min-width: 961px) {
  .topics .list.archive li:nth-child(2n) {
    margin: 0 1.3% 20px 0;
  }
}

@media only screen and (min-width: 961px) {
  .topics .list.archive li:nth-child(4n) {
    margin: 0 0 20px 0;
  }
}

.topics .list.archive.listStyle li {
  width: 100%;
  margin: 0 0 10px 0;
  display: block;
}

.topics .list.archive.listStyle li .thumbnail {
  display: none;
}

@media only screen and (min-width: 961px) {
  .topics .list.archive.listStyle li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .topics .list.archive.listStyle li:nth-child(2n) {
    margin: 0 0 10px 0;
  }
}

.topics .dateCreated {
  font-weight: bold;
  margin: 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.topics .dateCreated svg {
  font-size: 1rem;
  margin: 0 5px 0 0;
}
