@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
ul {
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
  color: darkblue;
}

a:hover {
  text-decoration: underline;
}

#root .breadCrumb {
  margin: 20px 0;
  font-size: 0.85rem;
}

#root .breadCrumb p {
  font-size: 0.85rem;
}

img {
  max-width: 100%;
}

.AppInner {
  display: none;
}

.loading {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: linear-gradient(70deg, #5b00d0 30%, #1265ff 90%);
  z-index: 110;
  opacity: 0;
  -webkit-transition: opacity 600ms ease-in-out;
  transition: opacity 600ms ease-in-out;
}

.content {
  padding: 34px 20px 50px;
  min-height: 100vh;
}

@media only screen and (min-width: 961px) {
  .content {
    margin: 0 auto;
  }
}

main {
  padding: 20px 0 0;
  max-width: 1200px;
  margin: 0 auto;
}

.heading01 {
  color: #4600A7;
}

.heading02 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4600A7;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.heading02 .MuiSvgIcon-root {
  margin: 0 5px 0 0;
}

.heading03 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4600A7;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.heading03 svg {
  margin: 0 5px 0 0;
  font-size: 1rem;
}

.toTopButton {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 100;
  display: none;
  cursor: pointer;
}

.toTopButton svg {
  font-size: 3rem;
  color: #5B01CF;
}

/* parts style */
.mainVisual {
  text-align: center;
}

@media only screen and (min-width: 415px) {
  .mainVisual img {
    max-width: 400px;
  }
}

.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.list li {
  text-align: left;
  margin: 0 0 10px;
}

@media only screen and (min-width: 961px) {
  .list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.list .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 0 5px;
  font-weight: bold;
}

@media only screen and (min-width: 961px) {
  .list .date {
    margin: 0 10px 5px 0;
  }
}

.list .date .tag {
  width: 60px;
  text-align: center;
  padding: 1px 5px;
  font-size: 0.6rem;
  margin: 0 0 0 5px;
}

.info {
  padding: 20px;
  margin: 0 0 50px;
}

.info .heading03:first-child {
  margin-top: 0;
}

.tagContainer {
  margin: 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.tagContainer + .tagContainer {
  margin: 0 0 10px;
}

.tagContainer .tag {
  margin: 0 10px 5px 0;
  padding: 1px 5px;
  font-size: 0.6rem;
}

.tag {
  background: lightgrey;
}

.tag.midkniteeuro {
  color: white;
  background: #133D97;
}

.tag.midkniteusa {
  color: white;
  background: red;
}

.tag.midkniteasia {
  color: white;
  background: orange;
}

.tag.midknitepacific {
  color: white;
  background: skyblue;
}

.tag.midknitelatin {
  color: white;
  background: darkgreen;
}

.tag.midkniteafrica {
  color: white;
  background: brown;
}

.tag.midknitejapan {
  color: white;
  background: blue;
}

.tag.midkniteblue {
  color: white;
  background: deepskyblue;
}

.tag.tour {
  color: white;
  background: lime;
}

.tag.international {
  color: white;
  background: darkblue;
}

.tag.domestic {
  color: white;
  background: red;
}

.expansionPanel {
  margin: 20px 0;
}

.checkBox {
  margin: 0 0 20px;
}

.checkBox .formGroup {
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
      -ms-flex-direction: initial;
          flex-direction: initial;
}

.handleCheckButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.handleCheckButton button {
  margin: 0 10px 10px 0;
  font-size: 0.6rem;
}

.buttonLinkText {
  color: white;
}

.buttonLinkText:hover {
  text-decoration: none;
}

.content .myButton {
  padding: 0;
}

.content .myButton .MuiButton-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.content .myButton .MuiButton-label a {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
