@charset "UTF-8";
/*ブレークポイント設定*/
/*色設定*/
/*要素幅指定*/
/*mainの左右paddingを相殺*/
/*文字サイズ設定*/
/*input placeholder*/
@-webkit-keyframes typing {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 23ch;
    opacity: 1;
  }
}
@keyframes typing {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 23ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing2 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 20ch;
    opacity: 1;
  }
}

@keyframes typing2 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 20ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing3 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 11ch;
    opacity: 1;
  }
}

@keyframes typing3 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 11ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing4 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 13ch;
    opacity: 1;
  }
}

@keyframes typing4 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 13ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing5 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 28ch;
    opacity: 1;
  }
}

@keyframes typing5 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 28ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing51 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 17ch;
    opacity: 1;
  }
}

@keyframes typing51 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 17ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing52 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 10ch;
    opacity: 1;
  }
}

@keyframes typing52 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 10ch;
    opacity: 1;
  }
}

@-webkit-keyframes typing6 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 15ch;
    opacity: 1;
  }
}

@keyframes typing6 {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 15ch;
    opacity: 1;
  }
}

@-webkit-keyframes cursor {
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: transparent;
  }
}

@keyframes cursor {
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: transparent;
  }
}

@-webkit-keyframes billboard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes billboard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mainVisualHome {
  margin: 0 -20px;
  height: 300px;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome {
    height: 400px;
  }
}

.mainVisualHome .mainVisualInner {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.mainVisualHome img {
  width: 100%;
  max-width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome img {
    height: 400px;
    margin: 0;
  }
}

.mainVisualHome .mainVisualText {
  font-family: "Courier New", monospace !important;
  position: absolute;
  left: 0;
  color: #5b00d0;
  background: linear-gradient(70deg, #5b00d0 30%, #1265ff 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  border-right: .05em solid;
  font-weight: bold;
  text-align: left;
  margin: 0;
  font-size: 1.4rem;
  letter-spacing: 1px;
  font-family: helvetica;
  padding: 10px;
  line-height: 1;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText {
    font-size: 2rem;
    padding: 0 20px;
  }
}

.mainVisualHome .mainVisualText.type1 {
  opacity: 0;
  top: 30px;
  -webkit-animation: typing 6s steps(20) 0s 1 normal forwards, cursor 0.3s steps(1) 20 normal both;
          animation: typing 6s steps(20) 0s 1 normal forwards, cursor 0.3s steps(1) 20 normal both;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText.type1 {
    top: 78px;
  }
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type1 {
    top: 10px;
  }
}

.mainVisualHome .mainVisualText.type2 {
  opacity: 0;
  top: 70px;
  -webkit-animation: typing2 5s steps(18) 6s 1 normal forwards, cursor 0.3s steps(1) 6s 18 normal both;
          animation: typing2 5s steps(18) 6s 1 normal forwards, cursor 0.3s steps(1) 6s 18 normal both;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText.type2 {
    top: 123px;
  }
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type2 {
    top: 50px;
  }
}

.mainVisualHome .mainVisualText.type3 {
  opacity: 0;
  top: 110px;
  -webkit-animation: typing3 2.7s steps(9) 11.4s 1 normal forwards, cursor 0.3s steps(1) 11.4s 9 normal both;
          animation: typing3 2.7s steps(9) 11.4s 1 normal forwards, cursor 0.3s steps(1) 11.4s 9 normal both;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText.type3 {
    top: 168px;
  }
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type3 {
    top: 90px;
  }
}

.mainVisualHome .mainVisualText.type4 {
  opacity: 0;
  top: 150px;
  -webkit-animation: typing4 3s steps(10) 14.1s 1 normal forwards, cursor 0.3s steps(1) 14.1s 10 normal both;
          animation: typing4 3s steps(10) 14.1s 1 normal forwards, cursor 0.3s steps(1) 14.1s 10 normal both;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText.type4 {
    top: 213px;
  }
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type4 {
    top: 130px;
  }
}

.mainVisualHome .mainVisualText.type5 {
  opacity: 0;
  top: 190px;
  -webkit-animation: typing5 7.5s steps(25) 17.1s 1 normal forwards, cursor 0.3s steps(1) 17.1s 25 normal both;
          animation: typing5 7.5s steps(25) 17.1s 1 normal forwards, cursor 0.3s steps(1) 17.1s 25 normal both;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText.type5 {
    top: 258px;
  }
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type5 {
    display: none;
  }
}

.mainVisualHome .mainVisualText.type51 {
  display: none;
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type51 {
    display: block;
    opacity: 0;
    top: 170px;
    -webkit-animation: typing51 4.5s steps(15) 17.1s 1 normal forwards, cursor 0.3s steps(1) 17.1s 15 normal both;
            animation: typing51 4.5s steps(15) 17.1s 1 normal forwards, cursor 0.3s steps(1) 17.1s 15 normal both;
  }
}

.mainVisualHome .mainVisualText.type52 {
  display: none;
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type52 {
    display: block;
    opacity: 0;
    top: 215px;
    -webkit-animation: typing52 2.4s steps(8) 21.6s 1 normal forwards, cursor 0.3s steps(1) 21.6s 8 normal both;
            animation: typing52 2.4s steps(8) 21.6s 1 normal forwards, cursor 0.3s steps(1) 21.6s 8 normal both;
  }
}

.mainVisualHome .mainVisualText.type6 {
  opacity: 0;
  top: 230px;
  -webkit-animation: typing6 3.3s steps(11) 24.6s 1 normal forwards, cursor 0.3s steps(1) infinite;
          animation: typing6 3.3s steps(11) 24.6s 1 normal forwards, cursor 0.3s steps(1) infinite;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .mainVisualText.type6 {
    top: 303px;
  }
}

@media only screen and (max-width: 374px) {
  .mainVisualHome .mainVisualText.type6 {
    top: 255px;
    -webkit-animation: typing6 3.3s steps(11) 24s 1 normal forwards, cursor 0.3s steps(1) infinite;
            animation: typing6 3.3s steps(11) 24s 1 normal forwards, cursor 0.3s steps(1) infinite;
  }
}

.mainVisualHome .billboard {
  position: absolute;
  opacity: 0;
  top: 65px;
  right: 20px;
}

@media only screen and (min-width: 961px) {
  .mainVisualHome .billboard {
    -webkit-animation: billboard 2s 27.9s forwards;
            animation: billboard 2s 27.9s forwards;
  }
}

.mainVisualHome .billboard img {
  width: 400px;
  height: 275px;
  -o-object-fit: contain;
     object-fit: contain;
}

.home .homeTopics {
  margin: 0 0 50px;
}

.home .homeContentBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.home .homeContentBox .homeContentPart {
  width: 48%;
  margin: 0 4% 20px 0;
}

.home .homeContentBox .homeContentPart:nth-child(2n) {
  margin: 0 0 20px 0;
}

@media only screen and (min-width: 961px) {
  .home .homeContentBox .homeContentPart {
    width: 24%;
    margin: 0 1.3% 0 0;
  }
  .home .homeContentBox .homeContentPart:nth-child(2n) {
    margin: 0 1.3% 0 0;
  }
  .home .homeContentBox .homeContentPart:nth-child(4n) {
    margin: 0;
  }
}

.home .homeContentBox .imageArea {
  text-align: center;
}

@media only screen and (min-width: 961px) {
  .home .homeContentBox .imageArea {
    margin: 0 0 50px;
  }
}

.home .list.archive {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.home .list.archive li {
  width: 48%;
  margin: 0 4% 20px 0;
  display: inline-block;
}

@media only screen and (min-width: 961px) {
  .home .list.archive li {
    width: 24%;
    margin: 0 1.3% 20px 0;
  }
}

.home .list.archive li:nth-child(2n) {
  margin: 0 0 10px 0;
}

@media only screen and (min-width: 961px) {
  .home .list.archive li:nth-child(2n) {
    margin: 0 1.3% 20px 0;
  }
}

@media only screen and (min-width: 961px) {
  .home .list.archive li:nth-child(4n) {
    margin: 0 0 20px 0;
  }
}
